

.score-label {
  width: fit-content;
  font-weight: 700;
  font-style: italic;
  font-size: 32px;
  line-height: 0.94em;
  color: white;
  padding: 0.2em;
}

.button-action__event-repeating-confirm {
  font-family: "Adineue Pro Cond";
  font-weight: 700px;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 5.5px;
}

.label__repeating-event {
  color: #999999;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-style: italic;
}
