.homeIcon {
    margin-left: 5%;
    padding-left: 0;
    padding-top: 16px;
    padding-bottom: 16px
}
.appBar {
    flex-grow: 1;
    background: #000000;
    transition: all 1s;
}
.hideHeader {
    top: -115px;
}
.loginButton {
    font-family: "Adineue Pro Cond";
    white-space: nowrap;
    margin-right: 5%;
}
.pageHeader-link {
    color: white;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
}
.pageHeader-link:hover {
    color: #4cd964;
}
.pageHeader-link:not(:first-child) {
    margin-left: 48px;
}