.bannerCover {
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: 1;
    left: 0;
}
.disabledButtonState.Mui-disabled {
    background-color: #333333;
    color: #666666;
}