@font-face {
  font-family: 'Adineue Pro Cond';
  src: local('Adineue Pro Cond'), url(./fonts/adineuePROCond-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Adineue Pro Cond Bold';
  src: local('Adineue Pro Cond'), url(./fonts/adineuePROCond-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Adineue Pro Cond Black';
  src: local('Adineue Pro Cond'), url(./fonts/adineuePROCond-Black.otf) format('opentype');
}

@font-face {
  font-family: 'Adineue Pro Cond Light';
  src: local('Adineue Pro Cond'), url(./fonts/adineuePROCond-Light.otf) format('opentype');
}

@font-face {
  font-family: 'AdiHausDIN Medium';
  src: local('AdiHausDIN Medium'), url(./fonts/AdihausDIN-Medium.woff) format('woff');
}

@font-face {
  font-family: 'AdiHausDIN Regular';
  src: local('AdiHausDIN Regular'), url(./fonts/AdihausDIN-Regular.woff) format('woff');
}

@font-face {
  font-family: 'AdihausDIN-CondIta';
  src: local('AdihausDIN-CondIta'), url(./fonts/AdihausDIN-CondIta.ttf) format('truetype');
}


html, body, #root {
  background: #0B0C0D;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'adihaus';
  color: black;
}

/** FIX YARN **/

body.ds {
  padding-top: 0;
}

.ds .btn-sm {
  height: auto;
}

option {
  color: black;
}

.tooltip-zindex {
  z-index: 9999;
}
*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.2);
  background-color: rgb(164, 164, 164);
}
@media screen and (max-width: 480px) {
  .CookieConsent {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.MuiChartsLegend-mark {
  rx: 10px
}
.MuiChartsLegend-root {
  transform: translateY(10px);
}
.MuiChartsAxis-tickLabel {
  transform: translateY(3px);
}
.MuiResponsiveChart-container {
  background-color: rgba(13, 13, 13);
}
.MuiMarkElement-root {
  scale: 0.25;
  stroke-width: 8;
}
.MuiChartsAxis-label {
  transform: translateY(90px);
}
.css-15uzly1-MuiChartsGrid-line {
  stroke: #4C4C4C;
};