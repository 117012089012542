.login {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    background: url("images/landing/loginMain.png") left top no-repeat;
    color: white;
}
.onboardingTitle {
    background-color: white;
    color: black;
    font-family: 'AdiHausDIN Regular';
    font-size: 52px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    padding: 16px 20px;
    margin-bottom: 10px;
    line-height: normal;
    height: 70px;
    display: inline-block;
}
.loginSelect-title {
    font-family: 'AdihausDIN-CondIta';
    font-size: 55px;
    margin-bottom: 32px;
    font-weight: 500;
    line-height: 90%; /* 49.5px */
    letter-spacing: 1px;
    text-transform: uppercase;
    color: white;
}
.loginSelect-description {
    font-family: 'AdiHausDIN Regular';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: white;
}

@media screen and (max-width: 480px){
    .onboardingTitle {
        font-size: 4rem;
    }
}
.login-leftSide {
    background: linear-gradient(143deg, #000 3.51%, rgba(0, 0, 0, 0.00) 56.39%);
}
.login-rightSide {
    background: #0B0C0D;
}
.login-logo__container {
    width: 100px;
    margin-bottom: 100px;
}
.login-logo__container img {
    width: 100%;
}
.login-part {
    flex: 1;
    display: flex;
}
.login-content {
    max-width: 450px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.login-rightSide .topHeader {
    display: none;
}
@media screen and (max-width: 768px) {
    .login {
        flex-direction: column;
    }
    .login-content {
        justify-content: start;
    }
    .login-part {
        padding: 30px;
    }
    .login-leftSide:not(.firstScreen) {
        display: none;
    }
    .login-rightSide .topHeader {
        margin-bottom: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .login-part {
        justify-content: center;
    }
    .login-part.login-rightSide {
        padding-top: 50px;
    }
    .login-rightSide button, .login-rightSide input{
        width: 100%;
    }
}
@media screen and (min-width: 769px) {
    .login-leftSide {
        width: 65%;
        align-items: flex-end;
        justify-content: end;
    }
    .login-rightSide {
        align-items: flex-start;
    }
}
@media screen and (min-width: 769px) and (max-width: 920px) {
    .login-rightSide, .login-leftSide {
        padding: 0 5%;
    }
}
@media screen and (min-width: 921px) {
    .login-rightSide, .login-leftSide {
        padding: 0 10%;
    }
}