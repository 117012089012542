.playVideoButton {
    all: unset;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    width: 110px;
}
.landingVideo {
    width: 100%;
    object-fit: fill;
}
@media screen and (min-width: 769px) {
    .safari.playVideoButton{
        display: none !important;
    }
}
.playButtonIcon {
    display: flex;
    opacity: 0.9;
}
.playButtonIcon:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: white;
    transform: scale(0.9);
    border-radius: 50%;
    z-index: 1;
}
.playVideoButton svg {
    width: 100%;
    height: 100%;
    z-index: 2;
}
.videoContainer {
    position: relative;
    margin-bottom: 36px;
}