.leaderBoard-datepicker {
    background-color: #0B0C0D;
}
.leaderBoard-datepicker__layout {
    background-color: #25272a;
}
.dayTimePicker-input {
    width: 100%;
}
.leaderBoard-datepicker .MuiPickersToolbar-content {
    align-items: flex-end;
}
.leaderBoard-datepicker-input .MuiOutlinedInput-root {
    border-radius: 4px;
}
.leaderBoard-datepicker-input input {
    padding: 12px 14px;
}
@media screen and (min-width: 600px) {
    .dayTimePicker-input {
        max-width: 210px;
    }
}