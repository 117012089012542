.MuiMobileStepper-root.featuresCarousel-stepper {
    background-color: transparent;
    margin-bottom: 2rem;
    justify-content: space-around;
}
.MuiMobileStepper-root.featuresCarousel-stepper .MuiMobileStepper-dot:hover {
    background-color: rgba(255, 255, 255, 0.08);
}
.MuiMobileStepper-root.featuresCarousel-stepper .MuiMobileStepper-dot:not(.MuiMobileStepper-dotActive) {
    background-color: #FFFFFF66;
    width: 5px;
    height: 5px;
    margin-inline: 5px;
}
.MuiMobileStepper-root.featuresCarousel-stepper .MuiMobileStepper-dotActive {
    background-color: #51F69D;
    margin-inline: 5px;
}
.MuiMobileStepper-root.featuresCarousel-stepper .MuiMobileStepper-dots {
    align-items: center;
}
.MuiMobileStepper-root.featuresCarousel-stepper button {
    font-size: 1.2rem;
    font-weight: 600;
}
@media screen and (max-width: 768px) {
    .react-swipeable-view-container {
        max-height: 430px;
    }
}