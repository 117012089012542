.navigation-tab {
    height: 72px;
}
.navigationIcon-button {
    padding: 12px;
}
.navigationIcon-button svg {
    width: 25px;
    height: 25px;
}
@media screen and (min-width: 600px) {
    .navigation-tab {
        min-width: 160px;
    }
}